import styled from 'styled-components';
import React from 'react';
import Logo from '../Logo';
import HeaderNavigation from './HeaderNavigation';
import { LEFT_MENU_WIDTH } from 'src/constants/layout';

function DesktopHeader() {
  return (
    <DesktopHeaderCss>
      <LogoContainerCss>
        <Logo />
      </LogoContainerCss>
      <HeaderNavigation />
    </DesktopHeaderCss>
  );
}

const LogoContainerCss = styled.div`
  width: ${LEFT_MENU_WIDTH};
  padding-left: ${p => `calc(${p.theme.sizes.spacing14})`};
  display: flex;
  align-items: center;
`;

const DesktopHeaderCss = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export default DesktopHeader;
