import React from 'react';
import { AdvertisementProvider } from 'src/contexts/advertisement';
import { Advertisement, Article, MenuItems } from 'src/services/articles';
import { DebugInfoBanner, DebugInfo } from '../DebugInfo';
import Layout from './Layout';
import { formatMenuItems } from 'src/utils/format-menu-items';

export default function withLayout<
  P extends {
    menuItems: MenuItems;
    adverts: Advertisement[] | null;
    title?: string;
    description?: string;
    debugInfo?: DebugInfo;
    article?: Article;
  },
>(WrappedComponent: React.ComponentType<P>) {
  return function withLayout(props: P) {
    const { popularTopics, allSports } = formatMenuItems(props.menuItems);

    return (
      <Layout
        popularTopics={popularTopics}
        allSports={allSports}
        title={props.title}
        description={props.description}
        ogImage={props?.article?.image?.url}
      >
        <AdvertisementProvider adverts={props.adverts}>
          <WrappedComponent {...props} />
          <DebugInfoBanner {...(props.debugInfo || {})} />
        </AdvertisementProvider>
      </Layout>
    );
  };
}
