export const IS_DEVELOP =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'develop';
export const IS_STAGING =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'staging';
export const IS_PREPROD =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'preprod';
export const IS_PRODUCTION =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'production';

export const BASE_URL = (() => {
  if (IS_STAGING) return 'https://news.shapegamesjack-web-staging.com/';
  if (IS_PREPROD) return 'https://news.shapegamesjack-web-preprod.com/';
  if (IS_PRODUCTION) return 'https://news.betjack.com/';

  return 'http://localhost:3000/';
})();
