import React, { useContext } from 'react';
import NextHead from 'next/head';
import {
  generateFontFaces,
  generateIconsFontFace,
} from '@shapegames/web-library-quick-draw-ui/dist/theme';
import { IS_PRODUCTION } from 'src/constants/enviroment';
import { FB_PIXEL_ID } from 'src/components/FacebookPixel/FacebookPixel';
import { ThemeContext } from 'styled-components';

export interface HeadProps {
  title?: string;
  description?: string;
  ogImage?: string;
}

function Head({ title, description, ogImage }: HeadProps) {
  const themeContext = useContext(ThemeContext);

  return (
    <NextHead>
      {IS_PRODUCTION && (
        <>
          <meta
            name="facebook-domain-verification"
            content="3zxnwk7qdi0tiwl20b022nepip3oxb"
          />
          <meta
            name="p:domain_verify"
            content="343679d6e33825656e6a488a0ba01343"
          />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-DRLE4Q2REW"
          ></script>
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-DRLE4Q2REW');`,
            }}
          ></script>

          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-7S6DR4WWLX"
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-7S6DR4WWLX');`,
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MZ35VZS');`,
            }}
          />

          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-193274800-1"
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'UA-193274800-1');`,
            }}
          />
        </>
      )}
      <title>{title}</title>

      <link rel="icon" href="/favicon.ico" />

      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0,viewport-fit=cover"
      />
      {title && <meta name="og:title" content={title} />}
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}
      {ogImage && <meta property="og:image" content={ogImage} />}
      <meta property="og:type" content="website" />

      <style>
        {`
          ${generateFontFaces(themeContext.fonts.primary)}
          ${generateFontFaces(themeContext.fonts.secondary)}
          ${generateIconsFontFace()}
        `}
      </style>

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      </noscript>
    </NextHead>
  );
}

export default Head;
