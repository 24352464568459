import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { MenuItems } from 'src/services/articles';
import { HamburgerMenuProvider, HamburgerMenu } from '../HamburgerMenu';
import HeaderNavigation from '../Header/HeaderNavigation';
import { MAX_LAYOUT_WIDTH, MAIN_BACKGROUND_COLOR } from 'src/constants/layout';
import Header from '../Header';
import Head, { HeadProps } from './Head';
import DesktopNavigationMenu from '../NavigationMenu/Desktop';
import MobileNavigationMenu from '../NavigationMenu/Mobile';

interface LayoutProps extends HeadProps {
  popularTopics: MenuItems['articleGroups'];
  allSports: MenuItems['tags'];
  children: ReactNode;
}

function Layout({
  popularTopics,
  allSports,
  children,
  title,
  description,
  ogImage,
}: LayoutProps) {
  return (
    <ContainerCss>
      <Head title={title} description={description} ogImage={ogImage} />
      <HamburgerMenuProvider>
        <Header />
        <HamburgerMenu>
          <HeaderNavigation variant="vertical" />
        </HamburgerMenu>
        <MobileNavigationMenu tags={[...allSports, ...popularTopics]} />
        <MainCss>
          <DesktopNavigationMenu
            allSports={allSports}
            popularTopics={popularTopics}
          />
          <MainBodyCss>{children}</MainBodyCss>
        </MainCss>
      </HamburgerMenuProvider>
    </ContainerCss>
  );
}

const ContainerCss = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
`;

const MainCss = styled.main`
  width: 100%;
  flex: 1;
  display: flex;
  min-width: 0;
  max-width: ${MAX_LAYOUT_WIDTH};

  /* minor tweak so there is no 
  gap between the header and the main content*/
  margin-top: -1px;
`;

const MainBodyCss = styled.div`
  background: ${MAIN_BACKGROUND_COLOR};
  width: 100%;
  flex: 1;
  padding-top: ${p => p.theme.sizes.spacing10};
  padding-left: ${p => p.theme.sizes.spacing10};
  padding-right: ${p => p.theme.sizes.spacing5};
  padding-bottom: ${p => p.theme.sizes.spacing10};

  ${p => p.theme.breakpoints.maxLg} {
    padding: ${p => `0 ${p.theme.sizes.spacing4}`};
    width: calc(100% - ${p => p.theme.sizes.spacing8});
  }
`;

export default Layout;
