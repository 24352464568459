import React from 'react';
import styles from './HamburgerMenuButton.module.css';
import cn from 'classnames';
import { useHamburgerMenu } from './HamburgerMenuContext';

interface HamburgerMenuProps {
  className?: string;
}

const HamburgerMenu = ({ className }: HamburgerMenuProps) => {
  const { visible, toggle } = useHamburgerMenu();
  return (
    <div className={cn(styles.navButton, className)} onClick={toggle}>
      <span className={visible ? styles.navIconClose : styles.navIcon} />
    </div>
  );
};

export default HamburgerMenu;
