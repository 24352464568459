import React from 'react';
import { HorizontalNavigationMenuItem } from '@shapegames/web-library-quick-draw-ui/dist/HorizontalNavigationMenu';
import { VerticalNavigationMenuItem } from '@shapegames/web-library-quick-draw-ui/dist/VerticalNavigationMenu';
import { MenuItems } from 'src/services/articles';
import Link from '../Link';

interface MenuListProps {
  type: 'desktop' | 'mobile';
  tags: MenuItems['tags'];
  iconFallback?: boolean;
}

function MenuList({ tags, type, iconFallback }: MenuListProps) {
  const mapTypeToComponent = {
    desktop: VerticalNavigationMenuItem,
    mobile: HorizontalNavigationMenuItem,
  };
  const Item = mapTypeToComponent[type];

  return (
    <>
      {tags?.map((it, idx) => (
        <Item
          key={`menu-item-${idx}`}
          text={it.name}
          iconFallbackContent={iconFallback ? it.name.slice(0, 1) : ''}
          iconName={it.icon ? `icon-category-${it.icon}` : ''}
          renderItem={content => <Link href={`/${it.slug}`}>{content}</Link>}
        />
      ))}
    </>
  );
}

export default MenuList;
