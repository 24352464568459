import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DebugInfo } from './types';

export default function DebugInfoBanner(props: DebugInfo) {
  const { query } = useRouter();

  const hasDebugMode = Boolean('debug' in query);
  const [isVisible, setIsVisible] = useState(hasDebugMode);

  useEffect(() => {
    if (hasDebugMode) {
      setIsVisible(true);
    }
  }, [hasDebugMode]);

  if (!isVisible) {
    return null;
  }

  return (
    <DebugInfoBannerCss visible={isVisible}>
      <div>
        <h2>Debug</h2>
        <button onClick={() => setIsVisible(false)}>X</button>
      </div>
      <table>
        <tbody>
          {Object.keys(props).map((key, idx) => (
            <tr key={`debug-info-${idx}`}>
              <td>{key}:</td>
              <td>{props[key as keyof DebugInfo] || 'n/a'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </DebugInfoBannerCss>
  );
}

const DebugInfoBannerCss = styled.div<{ visible?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;

  height: 100%;
  background: ${p => p.theme.colors.primary500};
  padding: ${p => p.theme.sizes.spacing4};
  color: white;
  transition: all 0.2s linear;

  ${p => p.theme.breakpoints.sm} {
    width: 700px;
  }

  ${p => p.theme.breakpoints.maxSm} {
    width: 300px;
  }

  z-index: 9999;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  button {
    background: black;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    color: white;
    border: 0;
  }

  table {
    width: 100%;
  }

  td {
    padding: ${p => p.theme.sizes.spacing1};
    padding-left: 0;
    max-width: 350px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${p => p.theme.breakpoints.maxSm} {
      max-width: 100px;
    }
  }

  td:first-child {
    ${p => p.theme.getFontStyle('primary', 'bold')}
    text-transform: capitalize;
  }
`;
