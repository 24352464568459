import React from 'react';
import { createContext, PropsWithChildren, useContext } from 'react';
import { Advertisement } from 'src/services/articles';

const advertisementContext = createContext<Advertisement[] | null>(null);

export const useAdverts = () => useContext(advertisementContext);

interface AdvertismentProviderProps {
  adverts: Advertisement[] | null;
}

export function AdvertisementProvider({
  adverts,
  children,
}: PropsWithChildren<AdvertismentProviderProps>) {
  return (
    <advertisementContext.Provider value={adverts}>
      {children}
    </advertisementContext.Provider>
  );
}
