import styled from 'styled-components';
import React from 'react';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

function Header() {
  return (
    <HeaderCss>
      <MobileHeader />
      <DesktopHeader />
    </HeaderCss>
  );
}

const HeaderCss = styled.header`
  position: relative;
  background: white;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  & > div:first-child {
    display: none;
  }

  ${p => p.theme.breakpoints.maxLg} {
    & > div:first-child {
      display: flex;
    }

    & > div:last-child {
      display: none;
    }
  }
`;

export default Header;
