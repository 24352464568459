// z-index constants
export const ZI_HEADER = 2;
export const ZI_HAMBURGER_MENU_OVERLAY = 9;
export const ZI_HAMRBUGER_MENU = 9999;

// global colors
export const MAIN_BACKGROUND_COLOR = '#f6f6f6';

// main layout parameters
export const LEFT_MENU_WIDTH = '250px';
export const MAX_LAYOUT_WIDTH = '1400px';

// article grid layout parameters
export const ARTICLES_LAYOUTS_GAP = '20px';
