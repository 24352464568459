import styled from 'styled-components';
import React from 'react';
import { MenuItems } from 'src/services/articles';
import { MAIN_BACKGROUND_COLOR } from 'src/constants/layout';
import { HorizontalNavigationMenu } from '@shapegames/web-library-quick-draw-ui/dist/HorizontalNavigationMenu';
import MenuList from './MenuList';

interface MobileNavigationMenuProps {
  tags: MenuItems['tags'];
}

function MobileNavigationMenu(props: MobileNavigationMenuProps) {
  return (
    <HorizontalNavigationMenuCss>
      <MenuList iconFallback={true} type="mobile" tags={props.tags} />
    </HorizontalNavigationMenuCss>
  );
}

const HorizontalNavigationMenuCss = styled(HorizontalNavigationMenu)`
  background: ${MAIN_BACKGROUND_COLOR};
  margin-top: -1px !important;

  ${p => p.theme.breakpoints.lg} {
    display: none !important;
  }
`;

export default MobileNavigationMenu;
