import styled from 'styled-components';
import React from 'react';
import Logo from '../Logo';
import HamburguerMenuButton from '../HamburgerMenu/HamburgerMenuButton';

function MobileHeader() {
  return (
    <MobileHeaderCss>
      <MenuIconCss />
      <Logo />
    </MobileHeaderCss>
  );
}

const MenuIconCss = styled(HamburguerMenuButton)`
  position: absolute;
  left: ${p => p.theme.sizes.spacing4};
  top: 50%;
  font-size: ${p => p.theme.typography.textXl};
  transform: translateY(-50%);
`;

const MobileHeaderCss = styled.div`
  position: relative;
  width: 100%;
  max-width: 1240px;
  display: flex;
  justify-content: center;
`;

export default MobileHeader;
