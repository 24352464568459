import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface HamburgerMenuProviderContext {
  visible: boolean;
  toggle: () => void;
}

export const HamburgerMenuContext = createContext<HamburgerMenuProviderContext>(
  {
    visible: false,
    toggle: () => null,
  },
);

export const useHamburgerMenu = () => useContext(HamburgerMenuContext);

export function HamburgerMenuProvider({ children }: { children: ReactNode }) {
  const [visible, setVisible] = useState(false);
  const toggle = () => setVisible(v => !v);
  return (
    <HamburgerMenuContext.Provider value={{ visible, toggle }}>
      {children}
    </HamburgerMenuContext.Provider>
  );
}
