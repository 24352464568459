import { MenuItems } from 'src/services/articles';

export const formatMenuItems = (menuItems: MenuItems | undefined) => {
  if (!menuItems) return { popularTopics: [], allSports: [] };

  const tagsWithIcons = menuItems?.tags
    .filter(item => !!item.icon)
    .map(generateTagWithSlug);

  const tagsWithoutIcons = menuItems?.tags
    .filter(item => !item.icon)
    .map(generateTagWithSlug);

  const articleGroups = menuItems.articleGroups;

  const popularTopics = [...(articleGroups || []), ...(tagsWithoutIcons || [])];
  const allSports = tagsWithIcons || [];

  return { popularTopics, allSports };
};

function generateTagWithSlug(tag: MenuItems['tags'][number]) {
  return { ...tag, slug: `tags/${tag.slug}` };
}
